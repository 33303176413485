import {useState} from "react";
import eye from "../../../../../assets/icons/eye.svg";
import eyeSlash from "../../../../../assets/icons/eye-slash.svg";
import addIcon from "../../../../../assets/icons/addIcon.svg";
import depositIcon from "../../../../../assets/icons/depositIconGreen.svg";
import {CustomModal} from "../../../../../components/ui";
import AccountDetailsModal from "./ModalAccountDetails";
import TransferModal from "./ModalTransfer";
import TransferToLoanWallet from "./TransferToLoanWallet";
import TransferToUser from "./TransferToUser";
import TransferToBank from "./TransferToBankAccount";
import ConfirmTransaction from "./ConfirmTransaction";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import ls from 'localstorage-slim';
import UserService from "../../../../../services/user.service";
import NotificationService from "../../../../../services/notification.service";
import AuthService from "../../../../../services/auth.service";
import {setUserInfo} from "../../../../../redux/reducers/authReducer";
import RepayLoanModal from "./ModalRepayLoan";

const account = {
    bank: "GreenSwift Bank ",
    number: "1234567890",
    holder: "Chris Morgan",
};


// LOCAL
ls.config.encrypt = true;

function WalletBalanceTab() {
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const userService = new UserService();
    const authService = new AuthService();
    const dispatch = useDispatch();

    const [isVisible, setIsVisible] = useState(true);
    const [showDepositModal, setShowDepositModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [transferTab, setTransferTab] = useState(0);
    const [transferType, setTransferType] = useState('');
    const [pinVerifyLoading, setPinVerifyLoading] = useState(false);
    const toggleIcon = isVisible ? eyeSlash : eye;
    const btnStyle =
        "flex items-center gap-x-2 px-[20px] py-[10px] border border-gs-primary rounded-[14px] text-[14.5px] tracking-wide";

    const closeTransferModal = () => {
        setShowTransferModal(false);
        setTransferTab(0);
    };

    const transferToBank = async (payload: any, hash: string) => {
        try {
            const response: any = await userService.transferToBank(payload, hash);
            return response;
        } catch (err) {
            throw err;
        }
    }
    const transferToGreenswift = async (payload: any, hash: string) => {
        try {
            const response: any = await userService.transferToGreenswift(payload, hash);
            return response;
        } catch (err) {
            throw err;
        }
    }
    const transferToLoanWallet = async (payload: any, hash: string) => {
        try {
            const response: any = await userService.transferToLoanWallet(payload, hash);
            return response;
        } catch (err) {
            throw err;
        }
    }

    const initiateTransfer = async (transferType: string, payload: any, hash: string) => {
        try {
            switch (transferType) {
                case 'bank':
                    return await transferToBank(payload, hash);
                case 'loanWallet':
                    return await transferToLoanWallet(payload, hash);
                case 'user':
                    return await transferToGreenswift(payload, hash);
                default:
                    throw new Error('Invalid transfer type');
            }
        } catch (error) {
            console.error('Transfer error:', error);

            // Type guard to check if error is an instance of Error
            if (error instanceof Error) {
                return {
                    status: false,
                    err: error.message || 'An error occurred during the transfer.',
                };
            } else {
                return {
                    status: false,
                    err: 'An unknown error occurred during the transfer.',
                };
            }
        }
    };

    const getUserInfo = async () => {
        try {
            const userData = await userService.getUserInfo();
            if (userData.status) {
                dispatch(setUserInfo(userData));
            }
        } catch (err) {
            // console.log(err)
            return;
        }
    }

    const handleTransaction = async (_arg: string | number) => {
        if (!_arg) {
            return;
        }

        const payload = {pin: _arg};
        const valuesFromLocal: any = ls.get('_tix');

        // Verify PIN
        setPinVerifyLoading(true);

        try {
            const response = await userService.pinVerify(payload);
            if (!response.status) {
                setPinVerifyLoading(false);
                NotificationService.error({message: response?.message});
                return;
            }

            // PIN verification successful
            const values = {...valuesFromLocal, pin: _arg};

            // Generate Transaction Hash
            const hashResponse = await userService.generateTrxHash();
            if (!hashResponse.status) {
                setPinVerifyLoading(false);
                NotificationService.error({
                    message: 'Transaction failed!',
                    addedText: hashResponse?.message
                });
                return;
            }

            // Transaction Hash generated successfully
            const trxToken = hashResponse.data.trxToken;
            const trxResponse: any = await initiateTransfer(transferType, values, trxToken);
            if (!trxResponse.status) {
                NotificationService.error({
                    message: 'Transaction failed!',
                    addedText: trxResponse?.err
                });
                setTransferTab(transferType === 'bank' ? 3 : transferType === 'loanWallet' ? 1 : 2);
            } else {
                NotificationService.success({message: trxResponse.message});
                closeTransferModal();
                setTransferTab(1);
                await getUserInfo();
                window.location.reload();
            }
        } catch (err) {
            console.error('Transaction error:', err);
            NotificationService.error({message: 'An error occurred during the transaction.'});
        } finally {
            setPinVerifyLoading(false); // Ensure loading state is cleared
        }
    };

    return (
        <>
            <div className="lg:w-2/5 w-full bg-gs-grayLess1 rounded-[10px]  py-[30px] grid gap-y-6">
                <h2 className="text-center text-[15px] font-[500] leading-[20px]">
                    WALLET BALANCE
                </h2>
                <div className="flex items-center justify-center gap-x-3">
                    <div
                        className="border-0 outline-0  tracking-wider text-right bg-transparent text-[28px] leading-[30px] font-extrabold">
                        {
                            isVisible ? (
                                `${userInfo?.localCurrencySymbol || ''}${Number(userInfo?.walletBalance || 0.00).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}`
                            ) : (
                                "*****"
                            )
                        }
                    </div>

                    <button onClick={() => setIsVisible(!isVisible)}>
                        <img src={toggleIcon} alt="toggle-icon"/>
                    </button>
                </div>

                <div className="flex justify-center gap-x-7">
                    <button
                        className={`${btnStyle} bg-gs-primary text-white`}
                        onClick={() => setShowDepositModal(true)}
                    >
                        <p>Deposit</p>
                        <img src={addIcon} alt="deposit"/>
                    </button>
                    <button
                        className={`${btnStyle} bg-white text-gs-primary`}
                        onClick={() => setShowTransferModal(true)}
                    >
                        <p>Transfer</p>
                        <img src={depositIcon} alt="transfer"/>
                    </button>
                </div>
            </div>

        {showDepositModal && (
            <CustomModal
                modalStyle="lg:w-[30%] w-[80%] mx-auto mt-[9%] bg-white rounded-[16px] p-[25px]"
                closeModal={() => setShowDepositModal(false)}
            >
                <AccountDetailsModal
                    data={account}
                    closeModal={() => setShowDepositModal(false)}
                />
            </CustomModal>
        )}

        {showTransferModal && (
            <CustomModal
                modalStyle="lg:w-[35%] w-[95%] mx-auto mt-[9%] h-fit max-h-[75vh] overflow-y-auto bg-white rounded-[16px] p-[25px]"
                closeModal={closeTransferModal}
            >
                {transferTab === 0 && (
                    <TransferModal
                        closeModal={() => setShowTransferModal(false)}
                        setTransferTab={(data) => setTransferTab(data)}
                    />
                )}
                {transferTab === 1 && (
                    <RepayLoanModal closeModal={closeTransferModal}/>
                )}
                {transferTab === 2 && (
                    <TransferToUser
                        closeModal={closeTransferModal}
                        setTransferTab={(data) => setTransferTab(data)}
                        setTransferType={(data) => setTransferType(data)}
                    />
                )}
                {transferTab === 3 && (
                    <TransferToBank
                        closeModal={closeTransferModal}
                        setTransferTab={(data) => setTransferTab(data)}
                        setTransferType={(data) => setTransferType(data)}
                    />
                )}
                {transferTab === 4 && (
                    <ConfirmTransaction
                        closeModal={closeTransferModal}
                        handlePinVerification={handleTransaction}
                        loading={pinVerifyLoading}
                    />
                )}
            </CustomModal>
        )
        }
        </>
    )
        ;
}

export default WalletBalanceTab;
