import {useEffect, useState} from "react";
import userService from "../../../../services/user.service";
import UserService from "../../../../services/user.service";
import {setUserInfo} from "../../../../redux/reducers/authReducer";
import {useDispatch} from "react-redux";

const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/<your_cloud_name>/upload";
const CLOUDINARY_UPLOAD_PRESET = "<your_upload_preset>";

function BasicInformationTab() {
    const initialState = {
        name: {
            first: 'Chris',
            middle: '',
            last: 'Chris'
        },
        tag: '',
        email: 'hubb@gmail.com',
        phone: '+234912345678',
        address: 'ofurie Avenue road 9',
        dob: '',
        gender: '',
        img: null,
    }
    const [formData, setFormData] = useState(initialState);
    const inputBoxStyle = "border text-[14px] text-gs-subText border-gs-borderInput className={`${inputBoxStyle}`} rounded-[8px] w-full p-3 outline-gs-primaryLess2";
    const labelStyle = "text-[18px] font-[600] leding-[22.5px] text-[#1B212D]";
    const smallTextStyle = "text-[13px] font-[400] leding-[20px] text-[#929EAE]";
    const innerLabelStyle = "text-[14px] leading-[14px] font-[400] text-gs-text";

    const [imageFile, setImageFile] = useState<File | null>(null); // Accept File or null
    const [isSubmitting, setIsSubmitting] = useState(false);

    const userService = new UserService();
    const dispatch = useDispatch();

    // Fetch data from the API on component mount
    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const userData = await (await userService.getUserInfo()).data;
                console.log("userData", userData);
                setFormData({
                    name: {
                        first: userData.firstName || '',
                        middle: userData.middleName || '',
                        last: userData.surname || ''
                    },
                    tag: userData.walletTag || '',
                    email: userData.email || '',
                    phone: userData.phone || '',
                    address: userData.address || '',
                    dob: userData.dob || '',
                    gender: userData.gender || '',
                    img: userData.img || null,
                });


                if (userData.status) {
                    dispatch(setUserInfo(userData));
                }
            } catch (err) {
                // console.log(err)
                return;
            }
        }

        getUserInfo();
    }, []);

    // Handle image upload to Cloudinary
    const uploadImage = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

        try {
            const response = await fetch(CLOUDINARY_URL, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            return data.secure_url;  // Return the Cloudinary image URL
        } catch (error) {
            console.error("Error uploading image:", error);
            return null;
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        setIsSubmitting(true);
        let imageUrl = formData.img;

        // If a new image is selected, upload to Cloudinary
        if (imageFile) {
            imageUrl = await uploadImage(imageFile);
        }

        const updatedData = {
            ...formData,
            img: imageUrl,
        };

        try {
            const response = await fetch("https://api.example.com/user-update", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });

            const result = await response.json();
            console.log("Update successful:", result);
        } catch (error) {
            console.error("Error updating user information:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Handle file selection for image upload
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setImageFile(file);
        }
    };

    return (
        <div
            className="pt-[2.2rem] pb-[4rem] px-[1.8rem] bg-white border border-gs-borderInput rounded-[8px] grid gap-y-[2.1rem]">
            <div>
                <h2 className="text-[18px] font-[600] leading-[22px] text-gs-subText tracking-wide">Personal
                    Information</h2>
                <div
                    className="h-[87px] w-[87px] rounded-full bg-gs-primary mt-[2.5rem] flex items-center justify-center text-[30px] text-white">
                    {formData.img ?
                        <img className="h-[87px] w-[87px] rounded-full bg-gs-primary text-center" src={formData.img}
                             alt='user-avatar'/> : 'CM'}
                </div>
            </div>

            <div className="grid grid-cols-5 gap-x-[3rem] items-center">
                <div className="col-span-1 grid">
                    <label className={`${labelStyle}`}>Full Name</label>
                    <small className={`${smallTextStyle}`}>Your legal name</small>
                </div>
                <div className="col-span-4 flex gap-x-4">
                    <div>
                        <label className={`${innerLabelStyle}`}>First name</label>
                        <input
                            className={`${inputBoxStyle} ${formData.name.first === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'} mt-1`}
                            value={formData.name.first}
                            onChange={(e) => setFormData({
                                ...formData,
                                name: {...formData.name, first: e.target.value}
                            })}
                            type="text"
                        />
                    </div>
                    <div>
                        <label className={`${innerLabelStyle}`}>Middle name</label>
                        <input
                            className={`${inputBoxStyle} ${formData.name.middle === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'} mt-1`}
                            value={formData.name.middle}
                            onChange={(e) => setFormData({
                                ...formData,
                                name: {...formData.name, middle: e.target.value}
                            })}
                            type="text"
                        />
                    </div>
                    <div>
                        <label className={`${innerLabelStyle}`}>Last name</label>
                        <input
                            className={`${inputBoxStyle} ${formData.name.last === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'} mt-1`}
                            type="text"
                            onChange={(e) => setFormData({...formData, name: {...formData.name, last: e.target.value}})}
                            value={formData.name.last}
                        />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-5 gap-x-[3rem] items-center">
                <div className="col-span-1 grid">
                    <label className={`${labelStyle}`}>GreenSwift tag</label>
                    <small className={`${smallTextStyle}`}>Send and receive money from anyone on GreenSwift </small>
                </div>
                <div className="col-span-4">
                    <div className="w-[50%]">
                        <input
                            disabled
                            className={`${inputBoxStyle} ${formData.tag === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'}`}
                            value={formData.tag}
                            onChange={(e) => setFormData({...formData, tag: e.target.value})}
                            type="text"
                        />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-5 gap-x-[3rem] items-center">
                <div className="col-span-1 grid">
                    <label className={`${labelStyle}`}>Email Address</label>
                    <small className={`${smallTextStyle}`}>Your registered email address</small>
                </div>
                <div className="col-span-4">
                    <div className="w-[50%]">
                        <input
                            className={`${inputBoxStyle} ${formData.email === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'}`}
                            type="email"
                            disabled
                            value={formData.email}
                        />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-5 gap-x-[3rem] items-center">
                <div className="col-span-1 grid">
                    <label className={`${labelStyle}`}>Phone Number</label>
                    <small className={`${smallTextStyle}`}>Your registered phone number</small>
                </div>
                <div className="col-span-4">
                    <div className="w-[50%]">
                        <input
                            className={`${inputBoxStyle} ${formData.phone === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'}`}
                            type="text"
                            onChange={(e) => setFormData({...formData, phone: e.target.value})}
                            value={formData.phone}
                        />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-5 gap-x-[3rem] items-center">
                <div className="col-span-1 grid">
                    <label className={`${labelStyle}`}>Address</label>
                    <small className={`${smallTextStyle}`}>Your registered address</small>
                </div>
                <div className="col-span-4">
                    <div className="w-[50%]">
                        <input
                            className={`${inputBoxStyle} ${formData.address === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'}`}
                            type="text"
                            onChange={(e) => setFormData({...formData, address: e.target.value})}
                            value={formData.address}
                        />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-5 gap-x-[3rem] items-center">
                <div className="col-span-1 grid">
                    <label className={`${labelStyle}`}>Other Details</label>
                    <small className={`${smallTextStyle}`}>Other information</small>
                </div>
                <div className="col-span-4">
                    <div className="grid grid-cols-2 gap-x-6">
                        <div>
                            <label className={`${innerLabelStyle}`}>Gender</label>
                            <input
                                className={`${inputBoxStyle} ${formData.gender === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'} mt-1`}
                                value={formData.gender}
                                onChange={(e) => setFormData({...formData, gender: e.target.value})}
                                type="text"
                            />
                        </div>
                        <div>
                            <label className={`${innerLabelStyle}`}>Date of Birth</label>
                            <input
                                className={`${inputBoxStyle} ${formData.dob === '' ? 'bg-white' : 'bg-[#ACB5BB]/[.1]'} mt-1`}
                                value={formData.dob}
                                type="text"/>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <button
                    className="bg-[#EFF3FA80] text-[16px] text-gs-subSubText/[.5] rounded-[14px] px-[24px] py-[16px] mt-7">Save
                    changes
                </button>
            </div>
        </div>
    )
}

export default BasicInformationTab;
