import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppLayout} from "../../../../components/layouts";
import UserService from "../../../../services/user.service";
import {setUserInfo} from "../../../../redux/reducers/authReducer";
import NotificationService from "../../../../services/notification.service";
import Tabs from "./Tabs";
import {Button, CustomModal} from "../../../../components/ui";
import IncomingTrade from "./IncomingTrade";
import OutgoingTrade from "./OutgoingTrade";
import {RootState} from "../../../../redux/store";
import ModalCreateP2PSwap from "./components/ModalCreateP2PSwap";
import ConfirmTransaction from "./components/ConfirmTransaction";
import ls from "localstorage-slim";

const data = []

function P2P() {
    const dispatch = useDispatch();
    const userService = new UserService();
    const userInfo: any = useSelector((state: RootState) => state.auth.userInfo);

    const tabContainerStyle = "w-full border border-gs-borderInput rounded-[14.5px] bg-white p-[1.5rem] flex justify-between items-center";
    const boxStyle = "px-[2rem] py-[4rem] md:w-full w-fit";

    const [tab, setTab] = useState(0);
    const [tradeModal, setTradeModal] = useState(false);
    const [transferTab, setTransferTab] = useState(0);
    const [incomingData, setIncomingData] = useState([]);
    const [outgoingData, setOutgoingData] = useState([])
    const [pinVerifyLoading, setPinVerifyLoading] = useState(false);
    const [transferType, setTransferType] = useState('');

    const getUserInfo = async () => {
        try {
            const response = await userService.getUserInfo();
            if (response.status) {
                dispatch(setUserInfo(response.data));
            }
        } catch (err: any) {
            NotificationService.error({
                message: err.message
            })
        }
    }

    const getTradeHistory = async () => {
        try {
            const response = await userService.listP2pSwaps();
            console.log(response.data);
            if (!response.status) {
                NotificationService.error({
                    message: 'Failed!',
                    addedText: response?.message
                });
                return;
            }

            //my listed swaps
            const outgoingArr = response?.data?.filter((item: any) => item.initiatorUid === userInfo.uid);

            //all available
            const incomingArr = response?.data
            setIncomingData(incomingArr);
            setOutgoingData(outgoingArr);
        } catch (err: any) {
            NotificationService.error({
                message: 'Failed!',
                addedText: err?.message
            });
            return;
        }
    }

    useEffect(() => {
        getUserInfo();
        getTradeHistory();
    }, []);

    const refetchSwapHistory = () => {
        getTradeHistory();
    }

    const closeTransferModal = () => {
        setTradeModal(false);
        setTransferTab(0);
    };


    const createP2p = async (payload: any, hash: string) => {
        try {
            const response: any = await userService.initiateP2pSwap(payload, hash);
            return response;
        } catch (err) {
            throw err;
        }
    }


    const initiateTransfer = async (transferType: string, payload: any, hash: string) => {
        try {
            switch (transferType) {
                case 'p2p':
                    return await createP2p(payload, hash);
                default:
                    throw new Error('Invalid transfer type');
            }
        } catch (error) {
            console.error('Transfer error:', error);

            // Type guard to check if error is an instance of Error
            if (error instanceof Error) {
                return {
                    status: false,
                    err: error.message || 'An error occurred during the transfer.',
                };
            } else {
                return {
                    status: false,
                    err: 'An unknown error occurred during the transfer.',
                };
            }
        }
    };


    const handleTransaction = async (_arg: string | number) => {
        if (!_arg) {
            return;
        }

        const payload = {pin: _arg};
        const valuesFromLocal: any = ls.get('_tix');

        // Verify PIN
        setPinVerifyLoading(true);

        try {
            const response = await userService.pinVerify(payload);
            if (!response.status) {
                setPinVerifyLoading(false);
                NotificationService.error({message: response?.message});
                return;
            }

            // PIN verification successful
            const values = {...valuesFromLocal, pin: _arg};

            // Generate Transaction Hash
            const hashResponse = await userService.generateTrxHash();
            if (!hashResponse.status) {
                setPinVerifyLoading(false);
                NotificationService.error({
                    message: 'Transaction failed!',
                    addedText: hashResponse?.message
                });
                return;
            }

            // Transaction Hash generated successfully
            const trxToken = hashResponse.data.trxToken;
            const trxResponse: any = await initiateTransfer("p2p", values, trxToken);
            if (!trxResponse.status) {
                NotificationService.error({
                    message: 'Transaction failed!',
                    addedText: trxResponse?.err
                });
                setTransferTab(2);
            } else {
                NotificationService.success({message: trxResponse.message});
                closeTransferModal();
                setTransferTab(1);
                await getUserInfo();
                window.location.reload();
            }
        } catch (err) {
            console.error('Transaction error:', err);
            NotificationService.error({message: 'An error occurred during the transaction.'});
        } finally {
            setPinVerifyLoading(false); // Ensure loading state is cleared
        }
    };


    return (
        <AppLayout
            text={<h2 className="text-[24px] font-bold leading-[30px] text-[#1A1C1E]">P2P Trading</h2>}
        >
            <div className="relative">
                <div className={boxStyle}>
                    {/* tabs  */}
                    <div className={tabContainerStyle}>
                        <Tabs tab={tab} setTab={(data) => setTab(data)}/>
                        <Button
                            btnStyle="px-[30px] py-[10px] md:w-fit w-full border border-gs-primary"
                            category="button"
                            isInverted={false}
                            handleClick={() => setTradeModal(true)}
                            text="New P2P"
                        />
                    </div>
                </div>

                <div className={boxStyle}>
                    {tab === 0 && <IncomingTrade refetchSwapHistory={refetchSwapHistory} data={incomingData}/>}
                    {tab === 1 && <OutgoingTrade data={outgoingData}/>}
                </div>

                {tradeModal && (
                    <CustomModal
                        modalStyle="lg:w-[35%] w-[80%] mx-auto mt-[13%] bg-white rounded-[16px] p-[25px]"
                        closeModal={closeTransferModal}
                    >
                        {transferTab === 0 && (
                            <ModalCreateP2PSwap
                                closeModal={closeTransferModal}
                                setP2PTab={(data) => setTransferTab(data)}
                                setTransferType={(data) => setTransferType(data)}
                            />
                        )}
                        {transferTab === 4 && (
                            <ConfirmTransaction
                                closeModal={closeTransferModal}
                                handlePinVerification={handleTransaction}
                                loading={pinVerifyLoading}
                            />
                        )}
                    </CustomModal>
                )
                }
            </div>
        </AppLayout>
    )
}

export default P2P;
