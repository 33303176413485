import { Field, Form, Formik } from "formik";
import { Button } from "../../../components/ui";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link, useNavigate } from "react-router-dom";
import PasswordField from "./PasswordField";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import AuthService from "../../../services/auth.service";
import NotificationService from "../../../services/notification.service";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../redux/reducers/authReducer";
import { generateUniqueNumber } from "../../../components/custom-hooks";
import ls from 'localstorage-slim';
import WalletTagField from "./WalletTagField";
import { ScaleLoader } from "react-spinners";

export interface Values {
  firstName: string;
  middleName: string;
  surname: string;
  phone: string;
  email: string;
  walletTag?: string;
  password: string;
  referralCode?: string;
  termsAndCondition?: boolean;
}

// REUSABLE STYLING
const labelContainerStyling = "flex flex-col gap-[10px]";
const labelStyling = "text-sm leading-[14px] text-gs-text";
const errorStyling = "text-[12px] leading-[14px] text-red-700";
const inputContainerStyling =
  "w-full border rounded-2xl border-gs-borderInput py-[16px] pl-3 pr-6";
const fieldStyling =
  "w-full outline-none text-sm leading-[14px] text-gs-subText";
const rowContainerStyling =
  "flex flex-col md:flex-row max-w-[467px] w-full gap-7 justify-between";

// FORM VALIDATION
const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`;
};

const phoneRegExp = /^\+?[1-9]\d{1,14}$/;

const SignUpSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  middleName: Yup.string().required("Middle Name is required"),
  surname: Yup.string().required("Last Name is required"),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  walletTag: Yup.string().required("WalletTag is required"),
  password: Yup.string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters")
    .matches(/[0-9]/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
  referralCode: Yup.string(),
  termsAndCondition: Yup.bool().oneOf([true], 'Accept terms and conditions to proceed'),
});

const initialValues: Values = {
  firstName: "",
  middleName: "",
  surname: "",
  phone: "",
  email: "",
  walletTag: "",
  password: "",
  referralCode: "",
  termsAndCondition: false,
};

// LOCAL
ls.config.encrypt = true;

function SignUpForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authService = new AuthService();
  
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState<null | string>(null);
  const [tagError, setTagError] = useState<null | string>(null);

  const handleSignUp = async (values: Values) => {

    const payload = {...values }

    setLoading(true);
    try{
      const email_response = await authService.verifyEmail({ email: values.email });
      const tag_response = await authService.verifyTag({ username: values.walletTag });

      setLoading(false);

      // check email error from server 
      if(!email_response.status){
        setEmailError(email_response?.message);
        return
      }
      // check tag error from server
      if(!tag_response.status){
        setTagError(tag_response.data?.message);
        return;
      }
    console.log(tag_response);
      
      // encrypt and store data to localStorage 
      if(email_response.status ){
        setEmailError(null);
        setTagError(null);
        ls.set('gs-reg', payload);

        // send verification email 
        const otp_response = await authService.sendOTP({email: values.email, phone: values.phone})
        if(!otp_response.status){
          NotificationService.error({
            message: 'Failed to send OTP!',
            addedText: otp_response.message
          });
          return;
        }

        if(otp_response.data.sent){
          ls.set('gs-reg-ot', otp_response.data.code);
          navigate("/auth/verification");
        }
      }
        // NotificationService.success({
        //   message: response?.message
        // })
        // localStorage.setItem('gs', response?.data?.token);
        // dispatch(setUserInfo(response?.data));
      
    }catch(err: any){
      setLoading(false);
      NotificationService.error({
        message: 'Registration failed!',
        addedText: err?.message || err[0]?.message || err
      });
      return;
    }
  };


    return (
      <Formik
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        onSubmit={handleSignUp}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="py-8 flex flex-col gap-6">
            {/* ROW 1 */}
            <div className={rowContainerStyling}>
              {/* FIRST NAME */}
              <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
                <div className="">
                  <label htmlFor="firstName" className={labelStyling}>
                    First Name
                  </label>
                  {errors.firstName && touched.firstName ? (
                    <p className={errorStyling}>{errors.firstName}</p>
                  ) : null}
                </div>
                <div className={inputContainerStyling}>
                  <Field
                    id="firstName"
                    name="firstName"
                    placeholder="Enter your first name"
                    type="text"
                    className={fieldStyling}
                  />
                </div>
              </div>

              {/* MIDDLE NAME */}
              <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
                <div className="">
                  <label htmlFor="middleName" className={labelStyling}>
                    Middle Name
                  </label>
                  {errors.middleName && touched.middleName ? (
                    <p className={errorStyling}>{errors.middleName}</p>
                  ) : null}
                </div>
                <div className={inputContainerStyling}>
                  <Field
                    id="middleName"
                    name="middleName"
                    placeholder="Enter your middle name"
                    type="text"
                    className={fieldStyling}
                  />
                </div>
              </div>
            </div>

            {/* ROW 2 */}
            <div className={rowContainerStyling}>
              {/* LAST NAME */}
              <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
                <div className="">
                  <label htmlFor="surname" className={labelStyling}>
                    Last Name
                  </label>
                  {errors.surname && touched.surname ? (
                    <p className={errorStyling}>{errors.surname}</p>
                  ) : null}
                </div>
                <div className={inputContainerStyling}>
                  <Field
                    id="surname"
                    name="surname"
                    placeholder="Enter your last name"
                    type="text"
                    className={fieldStyling}
                  />
                </div>
              </div>

              {/* PHONE NUMBER */}
              <div className={`${labelContainerStyling} md:max-w-[219px] w-full`}>
                <div className="">
                  <label htmlFor="phone" className={labelStyling}>
                    Phone Number
                  </label>
                  {errors.phone && touched.phone ? (
                    <p className={errorStyling}>{errors.phone}</p>
                  ) : null}
                </div>
                <div className={inputContainerStyling}>
                  <Field
                    name="phone"
                    placeholder="Enter phone number"
                    type="tel"
                    className="outline-none"
                  >
                    {({ field, form }: { field?: any; form?: any }) => (
                      <PhoneInput
                        {...field}
                        international
                        // countryCallingCodeEditable={false}
                        withCountryCallingCode
                        defaultCountry="NG"
                        value={values.phone}
                        placeholder="8180000000"
                        className=""
                        onChange={(value) => setFieldValue('phone', value)}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>

            {/* EMAIL */}
            <div className={labelContainerStyling}>
              <label htmlFor="email" className={labelStyling}>
                Email
              </label>
              {errors.email && touched.email ? (
                <p className={errorStyling}>{errors.email}</p>
              ) : null}
              {emailError &&  <p className={errorStyling}>Email {emailError}</p>}
              <div className={inputContainerStyling}>
                <Field
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  type="email"
                  className={fieldStyling}
                />
              </div>
            </div>


            <Field 
              id="walletTag" 
              name="walletTag" 
              component={WalletTagField} 
              />
              {tagError &&  <p className={errorStyling}>Tag {tagError}</p>}

            <Field 
              id="password" 
              name="password" 
              component={PasswordField} 
              />

            {/* REEFERRAL CODE */}
            <div className={labelContainerStyling}>
              <label htmlFor="referralCode" className={labelStyling}>
                Referral code (optional)
              </label>
              {errors.referralCode && touched.referralCode ? (
                <p className={errorStyling}>{errors.referralCode}</p>
              ) : null}
              <div className={inputContainerStyling}>
                <Field
                  id="referralCode"
                  name="referralCode"
                  placeholder="Enter referral code"
                  type="text"
                  className={fieldStyling}
                />
              </div>
            </div>

            {/* CHECKBOX */}
            <div className="flex items-center gap-2">
              <Field
                name="termsAndCondition"
                className="w-6 h-6 border-2 border-gs-primary p-2 rounded-md cursor-pointer"
                >
                {({ field }: {field?: any}) => (
                  <Checkbox
                    {...field}
                    onChange={(e) => setFieldValue("termsAndCondition", e.target.checked)}
                    checked={values.termsAndCondition}
                    id="termsAndCondition"
                    sx={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "6px",
                      color: "#087139",
                      "&.Mui-checked": {
                        color: "#087139",
                      },
                    }}
                  />
                )}
              </Field>
              <label
                htmlFor="termsAndCondition"
                className="text-gs-text text-xs leading-[22px]"
              >
                I accept{" "}
                <Link to="/terms-&-conditions" className="text-gs-primary">
                  Terms & Conditions
                </Link>{" "}
                and{" "}
                <Link to="/privacy-policy" className="text-gs-primary">
                  Privacy Policy
                </Link>
              </label> 
            </div>
            {errors.termsAndCondition && touched.termsAndCondition ? (
                <p className={`${errorStyling} -mt-5`}>{errors.termsAndCondition}</p>
              ) : null}

            <Button
              isInverted={false}
              type="submit"
              text={loading ?  <ScaleLoader color='#fff' height={12} width={4} /> : 'Continue'}
              category="button"
              btnStyle="w-full py-3 px-6"
            />
          </Form>
        )}
      </Formik>
    );
}

export default SignUpForm;
