import closeIcon from "../../../../../assets/icons/closeIcon.svg";
import backIcon from "../../../../../assets/icons/arrow-left.svg";
import {Button} from "../../../../../components/ui";
import {useEffect, useState} from "react";
import UserService from "../../../../../services/user.service";
import NotificationService from "../../../../../services/notification.service";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import ls from "localstorage-slim";
import {ScaleLoader} from "react-spinners";

const labelStyle = "text-[14px] tracking-wide leading-[14px] font-[500] text-gs-text";
const inputStyle = "flex items-center w-full border rounded-[15px]";
const balanceStyle = "text-[12px] font-[700] text-gs-primary bg-gs-primaryLess1 px-3.5 py-1.5 tracking-wide rounded-[16px]";

interface TransferModalProps {
    closeModal: () => void;
    setTransferTab: (data: number) => void;
    setTransferType: (data: string) => void;
}

const initialValues = {
    recipientEmail: "",
    recipientName: "",
    amount: 0.0,
    status: "pending", // Default status
    description: "",
};

ls.config.encrypt = true;

function ForeignTransferToBank({closeModal, setTransferTab, setTransferType}: TransferModalProps) {
    const userInfo = useSelector((state: RootState) => state.auth.userInfo);
    const userService = new UserService();
    const [formData, setFormData] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [isAmountValid, setIsAmountValid] = useState(true);
    const [transactionCharge, setTransactionCharge] = useState(0.00)

    const handleAmountChange = async (event: any) => {
        const value = event.target.value;
        const pattern = /^\d+(\.\d+)?$/;

        if (pattern.test(value) || value === '') {
            setIsAmountValid(true);
            setFormData({...formData, amount: value})
            try {
                const response = await userService.transactionCharge({amount: value});
                if (!response) return;
                setTransactionCharge(response.data.amount);
            } catch (err) {
                console.log(err)
            }
        } else {
            setIsAmountValid(false);
        }
    }
    const handlePay = async (event: any) => {
        event.preventDefault();
        if (formData.amount > userInfo.walletBalance) {
            NotificationService.warn({message: "Insufficient balance"});
            return;
        }
        setLoading(true);
        try {
            await userService.sendPinAsOTP();
            ls.set("_tix", {...formData});
            NotificationService.success({message: "Transfer initiated"});
            setTransferTab(4);
            setTransferType("bank");
        } catch (err) {
            NotificationService.error({message: "Transfer failed!"});
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handlePay}>
            <div className="flex justify-between items-center mb-[1.2rem]">
                <div className="text-[18px] font-bold flex items-center gap-x-3">
                    <img src={backIcon} alt="back" onClick={() => setTransferTab(0)} className="cursor-pointer"/>
                    Back
                </div>
                <img src={closeIcon} alt="close" onClick={closeModal} className="cursor-pointer"/>
            </div>

            <h2 className="text-[18px] font-bold tracking-wide">Send to recipient</h2>

            <div className="mt-7">
                <label className={`${labelStyle}`}>Balance to send from</label>
                <div className={`${inputStyle} p-[12px] border-gs-passwordIndicator gap-x-3 bg-[#787D87]/[.05]`}>
                    <p className="text-[14px] font-[500] leading-[14px] tracking-wide">Foreign Wallet Balance</p>
                    <p className={`${balanceStyle}`}>{userInfo.foreignCurrencySymbol}{userInfo.foreignBalance}</p>
                </div>
            </div>

            <div className="mt-4">
                <label className={labelStyle}>Recipient Email</label>
                <input
                    type="email"
                    placeholder="Recipient's email"
                    value={formData.recipientEmail}
                    onChange={(e) => setFormData({...formData, recipientEmail: e.target.value})}
                    className={`${inputStyle} px-[12px] py-[15px]`}
                    required
                />
            </div>

            <div className="mt-4">
                <label className={labelStyle}>Recipient Name</label>
                <input
                    type="text"
                    placeholder="Recipient's name"
                    value={formData.recipientName}
                    onChange={(e) => setFormData({...formData, recipientName: e.target.value})}
                    className={`${inputStyle} px-[12px] py-[15px]`}
                    required
                />
            </div>

            <div className="mt-4">
                <div className="flex justify-between items-end mb-1.5">
                    <label className={`${labelStyle}`}>Amount to Send</label>
                    <label
                        className="px-3 py-[0.2rem] bg-[#E4B46C]/[.3] text-[12px] tracking-wide rounded-[16px]">{userInfo.foreignCurrencySymbol}{transactionCharge}</label>
                </div>
                <div
                    className={`${inputStyle} p-[12px] ${isAmountValid ? 'border-gs-borderInput' : 'border-red-600'} gap-x-3`}>
                    <div className="text-gs-subText">&#36;</div>
                    <input
                        type="text"
                        className="w-full outline-none text-gs-subText"
                        placeholder="0.00"
                        onChange={handleAmountChange}
                        required
                    />
                </div>
            </div>

            <div className="mt-4">
                <label className={labelStyle}>Description</label>
                <textarea
                    rows={2}
                    placeholder="Enter description here"
                    onChange={(e) => setFormData({...formData, description: e.target.value})}
                    className={`${inputStyle} resize-none px-[12px] py-[15px]`}
                ></textarea>
            </div>

            <div className="flex flex-wrap justify-end gap-5 mt-7">
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full"
                    category="button"
                    isInverted
                    onClick={closeModal}
                    text="Cancel"
                />
                <Button
                    btnStyle="px-[50px] py-[10px] md:w-fit w-full border border-gs-primary"
                    category="button"
                    type="submit"
                    text={loading ? <ScaleLoader color="#fff" height={12} width={4}/> : "Transfer"}
                    isInverted={false}
                />
            </div>
        </form>
    );
}

export default ForeignTransferToBank;
